<template>
    <div>
        {{$t('home')}}
        <!-- <editor-bar v-model="detail" :isClear="isClear" @change="change"></editor-bar> -->
    </div>
</template>

<script>
// import EditorBar from '@/components/editor'

export default {
    data() {
        return {
            isClear: false,
            detail: ""
        }
    },
    methods: {
        change(val) {
            console.log(val) 
        }
    },
    created() {

    },
    // components: { EditorBar }
}
</script>